// Based on https://www.w3.org/WAI/tutorials/menus/flyout/

class MrFlyOutMenu extends HTMLElement {
	/* Listen for other fly-out menus opening, so this one can close immediatly */
	#openEventHandler = ( e: Event ) => {
		if ( !e || !( e instanceof CustomEvent ) ) {
			return;
		}

		const detail = e.detail;
		if ( !detail || !detail.id ) {
			return;
		}

		if ( !this.getAttribute( 'id' ) || ( this.getAttribute( 'id' ) === detail.id ) ) {
			return;
		}

		if ( this.parentElement ) {
			this.parentElement.removeAttribute( 'flyout-active' );
		}

		this.setAttribute( 'aria-expanded', 'false' );
	};

	connectedCallback(): void {
		window.addEventListener( 'mr-fly-out-menu-trigger:openedMenu', this.#openEventHandler );
	}

	disconnectedCallback(): void {
		window.removeEventListener( 'mr-fly-out-menu-trigger:openedMenu', this.#openEventHandler );
	}
}

customElements.define( 'mr-fly-out-menu', MrFlyOutMenu );
