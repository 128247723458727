async function subscribeToKlaviyo( action: string, formData: FormData ) {
	const langProp = document.documentElement.getAttribute( 'lang' ) || 'en';
	const lang = ( langProp.split( '-' )[0] || 'en' ).toUpperCase();
	const region = ( langProp.split( '-' )[1] || 'eu' ).toUpperCase();

	// $fields: $consent, $source, $method_type, $method_id, $consent_version
	formData.set( '$fields', '$consent,$source,$method_type,$method_id,$consent_version,language,region,Accepts Marketing' );
	// $list_fields: $consent
	formData.set( '$list_fields', '$consent' );
	// $source: $embed
	formData.set( '$source', '$embed' );
	// $method_type: Klaviyo Form
	formData.set( '$method_type', 'Klaviyo Form' );
	// $method_id: embed
	formData.set( '$method_id', 'embed' );
	// $consent_version: Embed default text
	formData.set( '$consent_version', '1' );

	formData.set( 'language', lang );
	formData.set( 'region', region );

	if ( formData.get( '$consent' ) ) {
		formData.set( 'Accepts Marketing', 'true' );
	}

	if ( formData.get( 'site_campaign' ) ) {
		formData.set( '$fields', '$consent,$source,$method_type,$method_id,$consent_version,language,region,site_campaign,Accepts Marketing' );
	}

	const resp = await fetch( action, {
		body: formData,
		method: 'POST',
		mode: 'cors',
		credentials: 'omit',
	} );

	if ( 200 !== resp.status ) {
		throw new Error( 'Failed to subscribe' );
	}

	const data = await resp.json();

	if ( data.errors && 0 < data.errors.length ) {
		console.log( JSON.stringify( data.errors ) );
		throw new Error( 'Failed to subscribe' );
	}

	if ( !data.success ) {
		throw new Error( 'Failed to subscribe' );
	}
}

async function subscribeToKlaviyoWithErrorHandling( form: HTMLFormElement ) {
	const action = form.getAttribute( 'data-ajax-submit' ) || '';
	if ( !action ) {
		throw new Error( 'Action url must be set for Klaviyo form' );
	}

	const successContent = form.getAttribute( 'data-success-content' ) || '';
	const errorContent = form.getAttribute( 'data-error-content' ) || '';
	const messagesArea = document.getElementById( 'klaviyo-email-signup-messages' );
	if ( !messagesArea ) {
		throw new Error( 'A message area for Klaviyo forms must exist on the page' );
	}
	const messagesAreaText = messagesArea.querySelector( 'div[message-area-text]' );
	if ( !messagesAreaText ) {
		throw new Error( 'A message area text for Klaviyo forms must exist on the page' );
	}

	const formData = new FormData( form );
	for ( let i = 0; i < form.elements.length; i++ ) {
		const element = form.elements[i];
		if ( 'disabled' in element ) {
			( element as HTMLInputElement ).disabled = true;
		}
	}

	if ( form['klaviyo-email-signup-submit'] ) {
		form['klaviyo-email-signup-submit'].disabled = true;
	}

	form.setAttribute( 'data-submitting', '' );

	try {
		await subscribeToKlaviyo( action, formData );
		form.removeAttribute( 'data-submitting' );

		if ( successContent ) {
			messagesArea.hidden = false;
			messagesArea.removeAttribute( 'hidden' );
			messagesAreaText.innerHTML = successContent;
		}

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push( {
			event: 'newsletterSignup',
			ga: {
				event: {
					category: 'Newsletter Signup',
					action: 'Submit',
					label: window.location.href,
				},
			},
			fb: {
				lead: {
					content_category: 'Newsletter Signup',
					content_name: window.location.href,
				},
			},
		} );

	} catch ( e ) {
		form.removeAttribute( 'data-submitting' );

		if ( !errorContent ) {
			// rethrow if we can't show error messages to users.
			// this will notify us via Bugsnag
			throw e;
		}

		if ( e.message ) {
			console.warn( e.message );
		}

		messagesArea.hidden = false;
		messagesArea.removeAttribute( 'hidden' );
		messagesArea.classList.add( 'klaviyo-newsletter-subscribe__form__messages--error' );
		messagesAreaText.innerHTML = errorContent;
	}
}

let initKlaviyoSubscribe = () => {
	if ( !( 'FormData' in window ) ) {
		return;
	}

	if ( !( 'set' in window.FormData.prototype ) ) {
		return;
	}

	const form = document.getElementById( 'klaviyo-email-signup' ) as HTMLFormElement | null;
	if ( !form ) {
		return;
	}

	initKlaviyoSubscribe = () => {
		/* noop */
	};

	if ( 'IntersectionObserver' in window ) {
		const observer = new IntersectionObserver( ( entries ) => {
			if ( entries[0] && entries[0].isIntersecting ) {
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push( {
					event: 'newsletterSignupImpression',
					ga: {
						event: {
							category: 'Newsletter Signup',
							action: 'Impression',
							label: window.location.href,
						},
					},
				} );

				observer.unobserve( form );
			}
		} );
		observer.observe( form );
	}

	form.addEventListener( 'submit', ( event ) => {
		if ( !form ) {
			return;
		}

		event.preventDefault();
		event.stopPropagation();

		subscribeToKlaviyoWithErrorHandling( form );
	} );
};

initKlaviyoSubscribe();
requestAnimationFrame( () => {
	initKlaviyoSubscribe();
} );
window.addEventListener( 'load', () => {
	initKlaviyoSubscribe();
} );
