const ANIMATION_DURATION = 1920;

class MrDots extends HTMLElement {
	#isHovering = false;

	#timer: number|undefined;

	#isTouch = false;

	#mouseenterHandler = ( e: Event ): void => {
		if ( !e || !e.currentTarget ) {
			return;
		}

		const trigger = e.currentTarget;
		if ( !( trigger instanceof HTMLElement ) ) {
			return;
		}

		if ( !trigger ) {
			return;
		}

		if ( this.#isTouch ) {
			return;
		}

		this.#isHovering = true;

		trigger.classList.add( 'is-hover-active' );

		if ( 'undefined' !== typeof this.#timer ) {
			clearInterval( this.#timer );
			this.#timer = undefined;
		}

		this.#timer = window.setInterval( () => {
			this.checkState( trigger );
		}, ANIMATION_DURATION );
	};

	checkState( trigger: HTMLElement ): void {
		if ( this.#isHovering ) {
			return;
		}

		if ( trigger ) {
			trigger.classList.remove( 'is-hover-active' );
		}

		if ( 'undefined' !== typeof this.#timer ) {
			clearInterval( this.#timer );
			this.#timer = undefined;
		}
	}

	#mouseleaveHandler = (): void => {
		if ( this.#isTouch ) {
			return;
		}

		this.#isHovering = false;
	};

	connectedCallback() {
		this.addEventListener( 'touchstart', () => {
			this.#isTouch = true;
		}, {
			passive: true,
			once: true,
		} );

		requestAnimationFrame( () => {
			document.querySelectorAll( '.js-dots-trigger' ).forEach( ( el ) => {
				el.addEventListener( 'mouseenter', this.#mouseenterHandler );
				el.addEventListener( 'mouseleave', this.#mouseleaveHandler );
			} );
		} );
	}

	disconnectedCallback() {
		document.querySelectorAll( '.js-dots-trigger' ).forEach( ( el ) => {
			el.removeEventListener( 'mouseenter', this.#mouseenterHandler );
			el.removeEventListener( 'mouseleave', this.#mouseleaveHandler );
		} );
	}
}

customElements.define( 'mr-dots', MrDots );
