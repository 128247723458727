export function isFullscreenElement( element: HTMLElement ): element is FullscreenElement {
	return ( 'addEventListener' in element ) &&
		(
			( 'msRequestFullscreen' in element ) ||
			( 'mozRequestFullScreen' in element ) ||
			( 'webkitRequestFullscreen' in element ) ||
			( 'webkitEnterFullscreen' in element )
		);
}

export type FullscreenElement = HTMLElement & {
	msRequestFullscreen?(): void
	mozRequestFullScreen?(): void
	webkitRequestFullscreen?(): void
	webkitEnterFullscreen?(): void
};

export function isFullscreenMediaElement( element: HTMLElement ): element is FullscreenMediaElement {
	return ( 'addEventListener' in element ) &&
		( 'controls' in element ) &&
		(
			( 'msRequestFullscreen' in element ) ||
			( 'mozRequestFullScreen' in element ) ||
			( 'webkitRequestFullscreen' in element ) ||
			( 'webkitEnterFullscreen' in element )
		);
}

export type FullscreenMediaElement = HTMLMediaElement & {
	msRequestFullscreen?(): void
	mozRequestFullScreen?(): void
	webkitRequestFullscreen?(): void
	webkitEnterFullscreen?(): void
};
