// Showcase Items with CTA but without video should be fully clickable.
// This is a features targetted at mobile user so missing cursor: pointer is ok here.

window.addEventListener( 'click', ( e: MouseEvent ) => {
	if ( !( e.target instanceof HTMLElement ) ) {
		return;
	}

	if ( ( e.target instanceof HTMLAnchorElement ) ) {
		return;
	}

	if ( ( e.target instanceof HTMLButtonElement ) ) {
		return;
	}

	if ( e.target.closest( '.l-showcase__item' )?.querySelector( 'video, mr-hotspot' ) ) {
		return;
	}

	const ctaLinks = e.target.closest( '.l-showcase__item' )?.querySelectorAll( 'a.cta-link, a.button-v2' );
	if ( !ctaLinks ) {
		return;
	}

	const ctaLinksArr = Array.from( ctaLinks );
	if ( 1 !== ctaLinksArr.length ) {
		return;
	}

	if ( !ctaLinksArr[0] || !( ctaLinksArr[0] instanceof HTMLAnchorElement ) ) {
		return;
	}

	ctaLinksArr[0].click();
} );
