export const setText = ( node: Element | null, text: string ): void => {
	if ( !node ) {
		return;
	}

	const c = node.firstChild;
	if ( c && !c.nextSibling && 3 === c.nodeType ) {
		( c as Text ).data = text;
	} else {
		node.textContent = text;
	}
};
