export const throttle = ( fn: () => void, wait: number ): () => void => {
	let time: number | null;

	return ( (): void => {
		const now = Date.now();
		if ( !time || ( time + wait ) < now ) {
			fn();
			time = now;
		}
	} );
};
