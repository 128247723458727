// MrVideoProgress is a self contained progress bar for HTMLVideoElement
// supports :
// - progress bar with indicator
//
// contained HTML :
// - bar (class : .mr-video-progress__bar)
// - indicator (class : .mr-video-progress__indicator)

import { ScrubEvent } from './scrub';

class MrVideoProgress extends HTMLElement {
	#videoEl: HTMLVideoElement | null = null;

	#videoDuration = 0;

	#offsetWidth = 0;

	#bar: HTMLDivElement;

	#indicator: HTMLDivElement;

	#moving = false;

	// Getting offsetWidth is not free
	// Listen for resize and store the value
	#resizeHandler = (): void => {
		requestAnimationFrame( () => {
			this.#offsetWidth = this.offsetWidth;

			if ( !this.#moving ) {
				this.displayVideoCurrentTimeWithIndicator();
			}
		} );
	};

	// Duration changes on the video should update the indicator
	#durationchangedHandler = (): void => {
		requestAnimationFrame( () => {
			if ( !this.#videoEl ) {
				return;
			}

			if ( this.#videoEl.duration ) {
				this.#videoDuration = this.#videoEl.duration;
			}

			if ( !this.#moving ) {
				this.displayVideoCurrentTimeWithIndicator();
			}
		} );
	};

	// Play start on the video should start moving the indicator
	#playHandler = (): void => {
		requestAnimationFrame( () => {
			if ( !this.#videoEl ) {
				return;
			}

			if ( this.#videoEl.duration ) {
				this.#videoDuration = this.#videoEl.duration;
			}

			if ( !this.#moving ) {
				this.displayVideoCurrentTimeWithIndicator();
			}
		} );
	};

	// Timeupdates on the video should start moving the indicator
	#timeupdateHandler = (): void => {
		requestAnimationFrame( () => {
			if ( !this.#videoEl ) {
				return;
			}

			if ( this.#videoEl.duration ) {
				this.#videoDuration = this.#videoEl.duration;
			}

			if ( !this.#moving ) {
				this.displayVideoCurrentTimeWithIndicator();
			}
		} );
	};

	// Scrub on the video should start moving the indicator
	#scrubHandler = ( e: Event ): void => {
		if ( !( e instanceof ScrubEvent ) ) {
			return;
		}

		requestAnimationFrame( () => {
			if ( !this.#videoEl ) {
				return;
			}

			if ( this.#videoEl.duration ) {
				this.#videoDuration = this.#videoEl.duration;
			}

			if ( !this.#moving ) {
				this.displayScrubTimeWithIndicator( e.currentTime );
			}
		} );
	};

	constructor() {
		super();

		this.#bar = document.createElement( 'div' );
		this.#bar.classList.add( `${this.tagName.toLowerCase()}__bar` );

		this.#indicator = document.createElement( 'div' );
		this.#indicator.classList.add( `${this.tagName.toLowerCase()}__indicator` );
	}

	connectedCallback(): void {
		requestAnimationFrame( () => {
			this.appendChild( this.#bar );
			this.appendChild( this.#indicator );

			this.#offsetWidth = this.offsetWidth;
			window.addEventListener( 'resize', this.#resizeHandler );

			const video = this.getAttachedVideo();
			if ( video ) {
				video.addEventListener( 'play', this.#playHandler );
				video.addEventListener( 'timeupdate', this.#timeupdateHandler );
				video.addEventListener( 'durationchange', this.#durationchangedHandler );
				video.addEventListener( 'scrub', this.#scrubHandler );

				this.#videoEl = video;
				this.#videoDuration = video.duration;
			}
		} );
	}

	disconnectedCallback(): void {
		this.removeChild( this.#bar );
		this.removeChild( this.#indicator );

		window.removeEventListener( 'resize', this.#resizeHandler );

		if ( this.#videoEl ) {
			this.#videoEl.removeEventListener( 'play', this.#playHandler );
			this.#videoEl.removeEventListener( 'timeupdate', this.#timeupdateHandler );
			this.#videoEl.removeEventListener( 'durationchange', this.#durationchangedHandler );
			this.#videoEl.removeEventListener( 'scrub', this.#scrubHandler );
		}

		this.#videoEl = null;
	}

	private getAttachedVideo(): HTMLVideoElement | null {
		if ( this.#videoEl ) {
			return this.#videoEl;
		}

		const forId = this.getAttribute( 'for' );
		if ( !forId ) {
			return null;
		}

		const forEl = document.getElementById( forId );
		if ( !forEl || !( forEl instanceof HTMLVideoElement ) ) {
			return null;
		}

		return forEl;
	}

	private displayVideoCurrentTimeWithIndicator() {
		this.#moving = true;

		requestAnimationFrame( () => {
			if ( !this.#videoEl ) {
				this.#moving = false;

				return;
			}

			if ( this.#videoDuration ) {
				this.#indicator.style.transform = `translateX(${this.#offsetWidth * this.#videoEl.currentTime / this.#videoDuration}px) translateZ(0)`;
			} else {
				this.#indicator.style.transform = 'translateX(0) translateZ(0)';
			}

			if ( this.#videoEl.paused ) {
				this.#moving = false;

				return;
			}

			if ( !this.#moving ) {

				return;
			}

			this.displayVideoCurrentTimeWithIndicator();
		} );
	}

	private displayScrubTimeWithIndicator( jump: number ) {
		if ( !this.#videoEl ) {
			return;
		}

		if ( this.#videoDuration ) {
			this.#indicator.style.transform = `translateX(${this.#offsetWidth * jump / this.#videoDuration}px) translateZ(0)`;
		} else {
			this.#indicator.style.transform = 'translateX(0) translateZ(0)';
		}
	}
}

customElements.define( 'mr-video-progress', MrVideoProgress );
