/* Types */
import '../types/mr-globals.ts';

/* Mr Interactive */
import { MrInputSink } from '@mrhenry/wp--mr-interactive';

customElements.define( 'mr-input-sink', MrInputSink );


/* Modules */
import '../modules/dots';
import '../modules/showcase-item-cta-full-click';

/* New Stuff */
import '../modules/mr-accordion';
import '../modules/mr-fly-out-menu';
import '../modules/mr-fly-out-menu-trigger';
import '../modules/mr-navigation-overlay';
import '../modules/mr-klaviyo-newsletter-subscribe-popup';
