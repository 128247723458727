import { doOncePerVisit, MrModalDialog } from '@mrhenry/wp--mr-interactive';
import { DialogTrigger } from '../helpers/dialog-trigger';

class MrKlaviyoNewsletterSubscribePopup extends MrModalDialog {
	exitTrigger: DialogTrigger;

	scrollUpTrigger: DialogTrigger;

	scrollDownTrigger: DialogTrigger;

	constructor() {
		super();

		this.exitTrigger = new DialogTrigger( () => {
			this.scrollUpTrigger.cancel();
			this.maybeOpen();
		}, {
			trigger: 'exitIntent',
			percentDown: 0,
			percentUp: 0,
		} );

		this.scrollUpTrigger = new DialogTrigger( () => {
			this.exitTrigger.cancel();
			this.maybeOpen();
		}, {
			trigger: 'scrollUp',
			percentDown: 0,
			percentUp: 0.2,
		} );

		this.scrollDownTrigger = new DialogTrigger( () => {
			this.scrollUpTrigger.listen();
		}, {
			trigger: 'scrollDown',
			percentDown: 0.65,
			percentUp: 0,
		} );
	}

	override connectedCallback() {
		super.connectedCallback();

		this.exitTrigger.listen();
		this.scrollDownTrigger.listen();
	}

	async maybeOpen(): Promise<void> {
		if ( document.body.style.overflow === 'hidden' ) {
			window.setTimeout( () => {
				this.maybeOpen();
			}, 1000 );

			return;
		}

		const otherPotentialModals = Array.from( document.querySelectorAll<HTMLElement>( '[state]' ) ).filter( ( x ) => {
			if ( !( x instanceof MrModalDialog ) ) {
				return false;
			}

			if ( !x.state || x.state === 'closed' || x.state === 'broken' ) {
				return false;
			}

			return true;
		} );

		if ( otherPotentialModals.length ) {
			window.setTimeout( () => {
				this.maybeOpen();
			}, 1000 );

			return;
		}

		doOncePerVisit( 'newsletter-subscribe-popup', () => {
			this.updateState( 'open' );
		}, () => {
			this.exitTrigger.cancel();
			this.scrollUpTrigger.cancel();
			this.scrollDownTrigger.cancel();
		} );
	}

	override async willOpen(): Promise<void> {
		document.body.style.overflow = 'hidden';

		await super.willOpen();
	}

	override async willClose(): Promise<void> {
		document.body.style.overflow = 'visible';

		await super.willClose();
	}

	override openAnimations(): Array<KeyframeEffect> {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [];
	}

	override closeAnimations(): Array<KeyframeEffect> {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [];
	}
}

customElements.define( 'mr-klaviyo-newsletter-subscribe-popup', MrKlaviyoNewsletterSubscribePopup );
