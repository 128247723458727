import { calcCrowKm, currentPositionWithExistingPermissions, requestCurrentPosition } from '../helpers/position';
import { setText } from '../helpers/set-text';

interface Store {
	city: string;
	country: string;
	'10y': boolean;
	address: string;
	email: string;
	id: number;
	kind: string;
	latitude?: number;
	longitude?: number;
	marine_collection: boolean;
	notes: string;
	official_store: boolean;
	postalcode: string;
	products: string;
	region: string;
	street: string;
	telephone: string;
	title: string;
	website: string;

	distance?: number;
}

class MrStoreLocatorPreviewList extends HTMLElement {
	#observer: IntersectionObserver | null = null;

	connectedCallback() {
		this.#observer?.disconnect();

		if ( ( 'IntersectionObserver' in window ) ) {
			this.#observer = new IntersectionObserver(
				async( entries ) => {
					if ( 0 === entries.length ) {
						return;
					}

					entries.forEach( async( entry ) => {
						if ( entry.target === this && entry.isIntersecting ) {
							const position = await requestCurrentPosition();
							const products = JSON.parse( this.getAttribute( 'products' ) || '' );
							if ( Array.isArray( products ) ) {
								this.render( products, position );
							}
						}
					} );
				}
			);

			this.#observer.observe( this );

			return;
		}

		// fallback
		requestAnimationFrame( () => {
			try {
				const products = JSON.parse( this.getAttribute( 'products' ) || '' );
				if ( Array.isArray( products ) ) {
					this.render( products );
				}
			} catch ( err ) {
				console.warn( err );
			}
		} );
	}

	disconnectedCallback() {
		this.#observer?.disconnect();
	}

	async render( products: Array<string>, userPosition: GeolocationPosition|null = null ) {
		const query = window.encodeURIComponent( `{
				location {
					city {
						name
					}
					country {
						name
					}
				}
			}` );

		// First get the users current city and country.
		return fetch( `https://limbo.services/geo/graphql?query=${query}`, {
			method: 'GET',
			mode: 'cors',
			cache: 'default',
		} ).then( ( response ) => {

			// verify the response
			if ( !response.ok ) {
				throw new Error( `geo : ${response.status} ${response.statusText}` );
			}

			return response.json();

		} ).then( async( d ) => {
			// verify the geo response and extract the city and country
			if ( !d || !d.data ) {
				return;
			}

			const data = d.data;

			let city = '';
			let country = '';

			if ( data && data.location && data.location.city && data.location.city.name ) {
				city = data.location.city.name;
			}

			let position: GeolocationPosition|null = null;
			if ( userPosition ) {
				position = userPosition;
			} else {
				position = await currentPositionWithExistingPermissions();
			}

			if ( position ) {
				city = '';
			} else {
				city = `&city=${city}`;
			}

			if ( data && data.location && data.location.country && data.location.country.name ) {
				country = data.location.country.name;
			}

			let productsArg = '';
			if ( products.length ) {
				productsArg = '&products[]=' + ( products || [] ).join( '&products[]=' );
			}

			// fetch stores for the given city and country
			return this.getAllStores( country, city, productsArg );
		} ).then( async( storesData ) => {
			if ( !storesData ) {
				return;
			}

			let stores = storesData;

			let position: GeolocationPosition|null = null;
			if ( userPosition ) {
				position = userPosition;
			} else {
				position = await currentPositionWithExistingPermissions();
			}

			if ( position ) {
				const p = position;
				stores.forEach( ( store ) => {
					if ( store.latitude && store.longitude ) {
						store.distance = calcCrowKm(
							store.latitude,
							store.longitude,
							p.coords.latitude,
							p.coords.longitude
						);
					}
				} );

				stores = filterByTieredDistance( stores );
			}

			// Set count of stores
			setText( this.querySelector( '.js-stores-count' ), stores.length.toString() );

			// Show / hide the plural / singular labels
			const pluralLabel = this.querySelector( '.js-stores-count-plural' );
			const singularLabel = this.querySelector( '.js-stores-count-singular' );
			if ( pluralLabel && pluralLabel instanceof HTMLElement && singularLabel && singularLabel instanceof HTMLElement ) {
				if ( 1 === stores.length ) {
					pluralLabel.style.display = 'none';
					singularLabel.style.display = 'inline';
				} else {
					pluralLabel.style.display = 'inline';
					singularLabel.style.display = 'none';
				}
			}

			stores.sort( ( a, b ) => {
				if ( a.official_store && !b.official_store ) {
					return -1;
				}

				if ( !a.official_store && b.official_store ) {
					return 1;
				}

				if ( a.distance && !b.distance ) {
					return -1;
				}

				if ( !a.distance && b.distance ) {
					return 1;
				}

				if ( a.distance && b.distance ) {
					if ( a.distance < b.distance ) {
						return -1;
					}

					if ( a.distance > b.distance ) {
						return 1;
					}
				}

				if ( a.title < b.title ) {
					return -1;
				}

				if ( a.title > b.title ) {
					return 1;
				}

				return 0;
			} );

			return stores.slice( 0, 13 );
		} )
			.then( ( stores ) => {
				if ( !stores ) {
					return;
				}
				// update the html
				stores.forEach( ( store, index ) => {
					const title = this.querySelector( `.js-store-title-${index}` );
					const address = this.querySelector( `.js-store-address-${index}` );
					const telephone = this.querySelector( `.js-store-telephone-${index}` );
					const email = this.querySelector( `.js-store-email-${index}` );
					const website = this.querySelector( `.js-store-website-${index}` );
					const mapLink = this.querySelector( `.js-store-map-link-${index}` );

					setText( title, store.title );
					setText( address, store.address );
					setText( telephone, store.telephone );

					setText( email, store.email );
					if ( email && email instanceof HTMLAnchorElement ) {
						email.href = `mailto:${store.email}`;
					}

					setText( website, store.website );
					if ( website && website instanceof HTMLAnchorElement ) {
						website.href = store.website;
					}

					if ( ( !store.website && !store.email && !store.telephone ) && website?.closest( '.store-list__store__info' ) ) {
						website.closest( '.store-list__store__info' )?.setAttribute( 'hidden', '' );
					} else if ( website?.closest( '.store-list__store__info' ) ) {
						website.closest( '.store-list__store__info' )?.removeAttribute( 'hidden' );
					}

					if ( store.latitude && store.longitude && mapLink && mapLink instanceof HTMLAnchorElement ) {
						mapLink.href = `https://www.google.com/maps/search/${encodeURIComponent( store.title )}/@${store.latitude},${store.longitude},18z`;
					} else if ( mapLink && mapLink instanceof HTMLElement ) {
						mapLink.style.display = 'none';
					}

					// google maps url query format
					const distanceFromMe = this.querySelector( `.js-distance-from-me-${index}` );
					distanceFromMe?.setAttribute( 'coordinates', `${store.latitude},${store.longitude}` );

					const container = this.querySelector( `.js-store-${index}` );
					container?.removeAttribute( 'hidden' );
				} );

				// this returns true if there were stores
				return !!stores.length;
			} )
			.catch( ( err ) => {
				console.warn( err );

				return;
			} );
	}

	async getAllStores( country: string, city: string, productsArg: string ) {
		return fetch( `https://store-locator-manager.komono.com/wp-json/wp/v2/stores?page=1&orderby=title&order=asc&per_page=100&country=${country}${city}${productsArg}`, {
			method: 'GET',
			mode: 'cors',
			cache: 'default',
		} ).then( ( resp ) => {
			const response = checkIfResponseIsValid( resp );

			// set total
			const total = Number.parseInt( response.headers.get( 'X-WP-Total' ) || '0', 10 );
			const pages = Math.ceil( total / 100 );

			const remaining: Array<Promise<unknown>> = [
				response.json(),
			];

			for ( let i = 2; i < ( pages + 1 ); i++ ) {
				const pageP = fetch( `https://store-locator-manager.komono.com/wp-json/wp/v2/stores?page=${i}&orderby=title&order=asc&per_page=100&country=${country}${city}${productsArg}`, {
					method: 'GET',
					mode: 'cors',
					cache: 'default',
				} ).then( ( pagedResp ) => {
					const pagedResponse = checkIfResponseIsValid( pagedResp );

					return pagedResponse.json();
				} );

				remaining.push(
					pageP
				);
			}

			return Promise.all( remaining ).then( ( data ) => {
				const out: Array<Store> = [];
				data.forEach( ( entry ) => {
					const list = entry as Array<Store>;
					list.forEach( ( store ) => {
						out.push( store );
					} );
				} );

				return out;
			} );
		} );
	}
}

function checkIfResponseIsValid( response: Response ): Response {
	if ( !response ) {
		throw new Error( 'ksl : missing response' );
	}

	// verify the response
	if ( !response?.ok ) {
		throw new Error( `ksl : ${response.status} ${response.statusText}` );
	}

	return response;
}

// Not each country/region operates on the same scale.
// Try to find more than 13 stores in a certain radius and use this result as the "close to you" set.
function filterByTieredDistance( stores: Array<Store> ): Array<Store> {
	let closeStores = stores.filter( ( store ) => {
		return store.distance && 5 > store.distance;
	} );

	if ( 13 < closeStores.length ) {
		return closeStores;
	}

	closeStores = stores.filter( ( store ) => {
		return store.distance && 15 > store.distance;
	} );

	if ( 13 < closeStores.length ) {
		return closeStores;
	}

	closeStores = stores.filter( ( store ) => {
		return store.distance && 50 > store.distance;
	} );

	if ( 13 < closeStores.length ) {
		return closeStores;
	}

	closeStores = stores.filter( ( store ) => {
		return store.distance && 150 > store.distance;
	} );

	if ( 13 < closeStores.length ) {
		return closeStores;
	}

	closeStores = stores.filter( ( store ) => {
		return store.distance && 450 > store.distance;
	} );

	if ( 13 < closeStores.length ) {
		return closeStores;
	}

	return stores;
}

customElements.define( 'mr-store-locator-preview-list', MrStoreLocatorPreviewList );
