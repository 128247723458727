import { setText } from '../helpers/set-text';

function setCartCountInNavigation(): boolean {
	if ( !window.Mr?.cart?.item_count ) {
		return false;
	}

	let didSetCount = false;
	document.querySelectorAll( ( '.js-navigation-bar-info__cart-count' ) ).forEach( ( el ) => {
		setText( el, `${window.Mr?.cart?.item_count ?? '0'}` );
		didSetCount = true;
	} );

	return didSetCount;
}

function tryToSetCartCountInNavigation(): void {
	setTimeout( () => {
		if ( setCartCountInNavigation() ) {
			return;
		}

		tryToSetCartCountInNavigation();
	}, 500 );
}

tryToSetCartCountInNavigation();
