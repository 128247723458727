// MrUserInteracting detects if a user is interacting within itself
class MrUserInteracting extends HTMLElement {
	#interactionTimeout: number | null = null;

	#keyupHandler = ( e: KeyboardEvent ): void => {
		if ( document.activeElement && this.contains( document.activeElement ) ) {
			this.extendInteractionDeadline( 1280 );

			return;
		}

		if ( e.target && e.target instanceof Element && this.contains( e.target ) ) {
			this.extendInteractionDeadline( 1280 );

			return;
		}
	};

	#downHandler = (): void => {
		this.setUserIsInteracting();

		if ( null !== this.#interactionTimeout ) {
			window.clearTimeout( this.#interactionTimeout );
		}
	};

	#upHandler = (): void => {
		this.extendInteractionDeadline( 1280 );
	};

	#moveHandler = (): void => {
		this.extendInteractionDeadline( 1280 );
	};

	#mouseenterHandler = (): void => {
		this.extendInteractionDeadline( 1280 );
	};

	#mouseleaveHandler = (): void => {
		this.extendInteractionDeadline( 960 );
	};

	#focusinHandler = ( e: FocusEvent ): void => {
		if ( document.activeElement && this.contains( document.activeElement ) ) {
			this.extendInteractionDeadline( 1280 );

			return;
		}

		if ( e.target && e.target instanceof Element && this.contains( e.target ) ) {
			this.extendInteractionDeadline( 1280 );

			return;
		}
	};

	#swapHandler = (): void => {
		this.extendInteractionDeadline( 1280 );
	};

	connectedCallback(): void {
		this.addEventListener( 'mouseup', this.#upHandler );
		this.addEventListener( 'mousedown', this.#downHandler );
		this.addEventListener( 'mousemove', this.#moveHandler, {
			passive: true,
		} );

		this.addEventListener( 'touchend', this.#upHandler );
		this.addEventListener( 'touchstart', this.#downHandler, {
			passive: true,
		} );
		this.addEventListener( 'touchmove', this.#moveHandler, {
			passive: true,
		} );

		this.addEventListener( 'keyup', this.#keyupHandler );

		this.addEventListener( 'mouseenter', this.#mouseenterHandler );
		this.addEventListener( 'mouseleave', this.#mouseleaveHandler );


		this.addEventListener( 'focusin', this.#focusinHandler );

		this.addEventListener( 'swap', this.#swapHandler );

		this.extendInteractionDeadline( 2000 );
	}

	disconnectedCallback(): void {
		this.removeEventListener( 'mouseup', this.#upHandler );
		this.removeEventListener( 'mousedown', this.#downHandler );
		this.removeEventListener( 'mousemove', this.#moveHandler );

		this.removeEventListener( 'touchend', this.#upHandler );
		this.removeEventListener( 'touchstart', this.#downHandler );
		this.removeEventListener( 'touchmove', this.#moveHandler );

		this.removeEventListener( 'keyup', this.#keyupHandler );

		this.removeEventListener( 'mouseenter', this.#mouseenterHandler );
		this.removeEventListener( 'mouseleave', this.#mouseleaveHandler );

		this.removeEventListener( 'focusin', this.#focusinHandler );

		this.removeEventListener( 'swap', this.#swapHandler );
	}

	extendInteractionDeadline( amount: number ) {
		this.setUserIsInteracting();

		if ( null !== this.#interactionTimeout ) {
			window.clearTimeout( this.#interactionTimeout );
		}

		this.#interactionTimeout = window.setTimeout( () => {
			this.setUserIsPassive();
			this.#interactionTimeout = null;

			return;
		}, amount );
	}

	setUserIsInteracting() {
		this.setAttribute( 'interacting', '' );
	}

	setUserIsPassive() {
		this.removeAttribute( 'interacting' );
	}
}

customElements.define( 'mr-user-interacting', MrUserInteracting );
