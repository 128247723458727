let lastPosition: GeolocationPosition | null = null;
let positionRequestErrored = false;
let positionRequest: Promise<GeolocationPosition | null> | null = null;

async function _requestCurrentPosition(): Promise<GeolocationPosition | null> {
	if ( positionRequestErrored ) {
		return null;
	}

	if ( lastPosition ) {
		return lastPosition;
	}

	if ( navigator.geolocation ) {
		// 1. first request geo data.
		//   1.a. if permissions have been granted the promise will resolve with data
		//   1.b. otherwise permissions are asked
		//     1.b.I   permissions might be granted in time (data)
		//     1.b.II  request can be ignored
		//     1.b.III request can be denied
		//     1.b.IV  code can hang here (Browser Application doesn't have permissions)
		//
		// 2. a "soft timeout" handler will wait for 4 seconds
		//   2.a. if it fires it will re-request geo data (see 1.b.)
		//
		// 3. a "hard timeout" handler will wait for 5 seconds
		//   3.a. if it fires it will resolve with "null"

		const newGeoP: () => Promise<GeolocationPosition | null> = () => {
			return new Promise( ( resolve ) => {
				navigator.geolocation.getCurrentPosition( ( position ) => {
					lastPosition = position;
					resolve( position );
				}, () => {
					// This is a non criticial func, we skip errors here.
					positionRequestErrored = true;
					lastPosition = null;
					resolve( null );
				}, {
					timeout: 30 * 1000, // I don't trust support for this option yet, but good to set a timeout where ever possible.
				} );
			} );
		};

		// Soft timeout will retry the request.
		// This is because "getCurrentPosition" can hang without permissions.
		const softTimeout: Promise<GeolocationPosition | null> = new Promise( ( resolve ) => {
			setTimeout( () => {
				newGeoP().then( ( v ) => {
					resolve( v );
				} );
			}, 1000 * 4 );
		} );

		// Hard timeout just resolves "null".
		// Further up the stack we have a fallback that will be used when "null" is received
		const hardTimeout: Promise<GeolocationPosition | null> = new Promise( ( resolve ) => {
			setTimeout( () => {
				resolve( null );
			}, 1000 * 5 );
		} );

		return Promise.race( [
			newGeoP(),
			softTimeout,
			hardTimeout,
		] );
	}

	return null;
}

export async function requestCurrentPosition(): Promise<GeolocationPosition | null> {
	if ( positionRequest ) {
		const position = await positionRequest;

		return position;
	}

	positionRequest = _requestCurrentPosition();
	const position = await positionRequest;

	return position;
}

export async function currentPositionWithExistingPermissions(): Promise<GeolocationPosition | null> {
	const permissionStatus = await navigator?.permissions?.query( {
		name: 'geolocation',
	} );

	if ( !permissionStatus ) {
		return null;
	}

	if ( 'granted' !== permissionStatus?.state ) {
		return null;
	}

	return requestCurrentPosition();
}

// maths for distance in KM
export function calcCrowKm( lat1Arg: number, lon1: number, lat2Arg: number, lon2: number ): number {
	const R = 6371; // km
	const dLat = toRad( lat2Arg - lat1Arg );
	const dLon = toRad( lon2 - lon1 );
	const lat1 = toRad( lat1Arg );
	const lat2 = toRad( lat2Arg );

	const a = Math.sin( dLat / 2 ) * Math.sin( dLat / 2 ) + Math.sin( dLon / 2 ) * Math.sin( dLon / 2 ) * Math.cos( lat1 ) * Math.cos( lat2 );
	const c = 2 * Math.atan2( Math.sqrt( a ), Math.sqrt( 1 - a ) );
	const d = R * c;

	return Number( ( d ).toFixed( 1 ) );
}

// Converts numeric degrees to radians
export function toRad( value: number ): number {
	return value * Math.PI / 180;
}
