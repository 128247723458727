export class ScrubEvent extends CustomEvent<number> {
	get currentTime(): number {
		return this.detail;
	}
}

// MrVideoProgress is a self contained scrubber for HTMLVideoElement
// supports :
// - jump to time
// - scrub
class MrVideoScrub extends HTMLElement {
	#videoEl: HTMLVideoElement | null = null;

	#videoDuration = 0;

	#offsetWidth = 0;

	#videoStateOnMouseDown = {
		paused: true,
		currentTime: 0,
	};

	// Getting offsetWidth is not free
	// Listen for resize and store the value
	#resizeHandler = (): void => {
		requestAnimationFrame( () => {
			this.#offsetWidth = this.offsetWidth;
		} );
	};

	// Mousedown should setup scrubbing.
	#mousedownHandler = ( e: MouseEvent ): void => {
		e.preventDefault();

		if ( !this.#videoEl ) {
			return;
		}

		if ( !this.#offsetWidth ) {
			return;
		}

		this.setAttribute( 'scrubbing', '' );

		this.#videoDuration = this.#videoEl.duration;

		this.#videoStateOnMouseDown.paused = this.#videoEl.paused;
		this.#videoStateOnMouseDown.currentTime = this.#videoEl.currentTime;

		if ( !this.#videoEl.paused ) {
			this.#videoEl.pause();
		}

		// Only add the event listener in the best case
		this.addEventListener( 'mousemove', this.#mousemoveHandler, {
			passive: true,
		} );
	};

	#mousemoveHandler = ( e: MouseEvent ): void => {
		if ( !this.#videoEl ) {
			return;
		}

		if ( !this.#offsetWidth ) {
			return;
		}

		if ( !this.#videoDuration ) {
			return;
		}

		const length = this.#offsetWidth;
		const offset = this.getBoundingClientRect().left;

		// get position relative to scrub width
		// e.g. if user clicks in exact mid of scrub
		// position will be 0.5
		const position = ( e.clientX - offset ) / length;
		if ( !isNaN( position ) ) {
			let currentTime = Math.abs(
				Math.min(
					position * this.#videoDuration,
					this.#videoDuration
				)
			);

			// Floating point rounding combined with how video works can cause issues.
			// Never set currentTime to duration or above.
			if ( currentTime + 0.02 >= this.#videoDuration ) {
				currentTime = currentTime - 0.02;
			}

			this.#videoEl.dispatchEvent( new ScrubEvent( 'scrub', {
				detail: currentTime,
			} ) );

			this.#videoEl.currentTime = currentTime;
		}
	};

	// Clicks should jump the video.
	// After scrub the video should continue playing.
	// if it was playing when scrubbing started.
	#mouseupHandler = ( e: MouseEvent ): void => {
		// Always remove the event listener
		this.removeEventListener( 'mousemove', this.#mousemoveHandler );
		this.removeAttribute( 'scrubbing' );

		if ( !this.#videoEl ) {
			return;
		}

		if ( !this.#offsetWidth ) {
			return;
		}

		if ( !this.#videoDuration ) {
			return;
		}

		const length = this.#offsetWidth;
		const offset = this.getBoundingClientRect().left;

		// get position relative to scrub width
		// e.g. if user clicks in exact mid of scrub
		// position will be 0.5
		const position = ( e.clientX - offset ) / length;
		if ( !isNaN( position ) ) {
			let currentTime = Math.abs(
				Math.min(
					position * this.#videoDuration,
					this.#videoDuration
				)
			);

			// Floating point rounding combined with how video works can cause issues.
			// Never set currentTime to duration or above.
			if ( currentTime + 0.02 >= this.#videoDuration ) {
				currentTime = currentTime - 0.02;
			}

			this.#videoEl.currentTime = currentTime;
		}

		if ( !this.#videoStateOnMouseDown.paused && this.#videoEl.paused ) {
			const playPromise = this.#videoEl.play();
			if ( playPromise instanceof Promise ) {
				playPromise.then( () => {
					return; // noop.
				} ).catch( () => {
					// quickly seeking and scrubbing can cause the video to be paused again before playback starts.
					// these are expected errors and normal behaviour.
					return; // noop.
				} );
			}
		}
	};

	// Touchstart should setup scrubbing.
	#touchstartHandler = ( e: TouchEvent ): void => {
		e.preventDefault();

		if ( !this.#videoEl ) {
			return;
		}

		if ( !this.#offsetWidth ) {
			return;
		}

		this.setAttribute( 'scrubbing', '' );

		this.#videoDuration = this.#videoEl.duration;

		this.#videoStateOnMouseDown.paused = this.#videoEl.paused;
		this.#videoStateOnMouseDown.currentTime = this.#videoEl.currentTime;

		if ( !this.#videoEl.paused ) {
			this.#videoEl.pause();
		}

		// Only add the event listener in the best case
		this.addEventListener( 'touchmove', this.#touchmoveHandler, {
			passive: true,
		} );
	};

	#touchmoveHandler = ( e: TouchEvent ): void => {
		if ( !this.#videoEl ) {
			return;
		}

		if ( !this.#offsetWidth ) {
			return;
		}

		if ( !this.#videoDuration ) {
			return;
		}

		if ( !e.changedTouches.length ) {
			return;
		}

		const length = this.#offsetWidth;
		const offset = this.getBoundingClientRect().left;

		// get position relative to scrub width
		// e.g. if user clicks in exact mid of scrub
		// position will be 0.5
		const position = ( e.changedTouches[0].pageX - offset ) / length;
		if ( !isNaN( position ) ) {
			let currentTime = Math.abs(
				Math.min(
					position * this.#videoDuration,
					this.#videoDuration
				)
			);

			// Floating point rounding combined with how video works can cause issues.
			// Never set currentTime to duration or above.
			if ( currentTime + 0.02 >= this.#videoDuration ) {
				currentTime = currentTime - 0.02;
			}

			this.#videoEl.dispatchEvent( new ScrubEvent( 'scrub', {
				detail: currentTime,
			} ) );

			this.#videoEl.currentTime = currentTime;
		}
	};

	// Tab should jump the video.
	// After scrub the video should continue playing.
	// if it was playing when scrubbing started.
	#touchendHandler = ( e: TouchEvent ): void => {
		// Always remove the event listener
		this.removeEventListener( 'touchmove', this.#touchmoveHandler );
		this.removeAttribute( 'scrubbing' );

		if ( !this.#videoEl ) {
			return;
		}

		if ( !this.#offsetWidth ) {
			return;
		}

		if ( !this.#videoDuration ) {
			return;
		}

		if ( !e.changedTouches.length ) {
			return;
		}

		const length = this.#offsetWidth;
		const offset = this.getBoundingClientRect().left;

		// get position relative to scrub width
		// e.g. if user clicks in exact mid of scrub
		// position will be 0.5
		const position = ( e.changedTouches[0].pageX - offset ) / length;
		if ( !isNaN( position ) ) {
			let currentTime = Math.abs(
				Math.min(
					position * this.#videoDuration,
					this.#videoDuration
				)
			);

			// Floating point rounding combined with how video works can cause issues.
			// Never set currentTime to duration or above.
			if ( currentTime + 0.02 >= this.#videoDuration ) {
				currentTime = currentTime - 0.02;
			}

			this.#videoEl.currentTime = currentTime;
		}

		if ( !this.#videoStateOnMouseDown.paused && this.#videoEl.paused ) {
			const playPromise = this.#videoEl.play();
			if ( playPromise instanceof Promise ) {
				playPromise.then( () => {
					return; // noop.
				} ).catch( () => {
					// quickly seeking and scrubbing can cause the video to be paused again before playback starts.
					// these are expected errors and normal behaviour.
					return; // noop.
				} );
			}
		}
	};

	connectedCallback(): void {
		this.addEventListener( 'mouseup', this.#mouseupHandler );
		this.addEventListener( 'mousedown', this.#mousedownHandler );

		this.addEventListener( 'touchstart', this.#touchstartHandler, {
			passive: true,
		} );
		this.addEventListener( 'touchend', this.#touchendHandler );

		requestAnimationFrame( () => {
			this.#offsetWidth = this.offsetWidth;
			window.addEventListener( 'resize', this.#resizeHandler );

			const video = this.getAttachedVideo();
			if ( video ) {
				this.#videoEl = video;
				this.#videoDuration = video.duration;
			}
		} );
	}

	disconnectedCallback(): void {
		this.removeEventListener( 'mouseup', this.#mouseupHandler );
		this.removeEventListener( 'mousemove', this.#mousemoveHandler );
		this.removeEventListener( 'mousedown', this.#mousedownHandler );

		this.removeEventListener( 'touchstart', this.#touchstartHandler );
		this.removeEventListener( 'touchmove', this.#touchmoveHandler );
		this.removeEventListener( 'touchend', this.#touchendHandler );

		window.removeEventListener( 'resize', this.#resizeHandler );
	}

	private getAttachedVideo(): HTMLVideoElement | null {
		if ( this.#videoEl ) {
			return this.#videoEl;
		}

		const forId = this.getAttribute( 'for' );
		if ( !forId ) {
			return null;
		}

		const forEl = document.getElementById( forId );
		if ( !forEl || !( forEl instanceof HTMLVideoElement ) ) {
			return null;
		}

		return forEl;
	}
}

customElements.define( 'mr-video-scrub', MrVideoScrub );
