class MrCountdown extends HTMLElement {
	#ticker = 0;

	#lastRenderedSecond = -1;

	connectedCallback() {
		if ( this.#ticker ) {
			window.clearInterval( this.#ticker );
		}

		requestAnimationFrame( () => {
			this.update();
		} );

		this.#ticker = window.setInterval( () => {
			const currentSecond = Math.floor( ( Date.now() / 1000 ) % 60 );
			if ( currentSecond !== this.#lastRenderedSecond ) {
				this.update();

				this.#lastRenderedSecond = currentSecond;
			}
		}, 100 );
	}

	update() {
		if ( !this.hasAttribute( 'data-timestamp' ) ) {
			return;
		}

		const target = parseInt( this.getAttribute( 'data-timestamp' ) ?? '0', 10 ) * 1000;
		if ( !target ) {
			return;
		}

		const now = Date.now();
		const diff = target - now;


		const mrCountdownClock = this.querySelector( '[mr-countdown-clock]' );
		if ( !mrCountdownClock ) {
			return;
		}

		if ( 0 < diff ) {
			mrCountdownClock.setAttribute( 'is-counting-down', '' );
			mrCountdownClock.removeAttribute( 'has-finished' );

			this.setAttribute( 'is-counting-down', '' );
			this.removeAttribute( 'has-finished' );
		} else {
			mrCountdownClock.removeAttribute( 'is-counting-down' );
			mrCountdownClock.setAttribute( 'has-finished', '' );

			this.removeAttribute( 'is-counting-down' );
			this.setAttribute( 'has-finished', '' );
		}

		const data = this.countdown( diff );

		const countdownElements = this.querySelectorAll( '.countdown__part__number' );
		if ( !countdownElements || 3 !== countdownElements.length ) {
			return;
		}

		for ( let i = 0; i < data.length; i++ ) {
			const countdownNumber = data[i];
			const countdownElement = countdownElements[i];

			countdownElement.innerHTML = countdownNumber;
		}
	}

	countdown( diff: number ) {
		const days = Math.floor( diff / 86400000 ) * 24;
		const hours = Math.floor( ( diff / 3600000 ) % 24 ) + days;
		const minutes = Math.floor( ( diff / 60000 ) % 60 );
		const seconds = Math.floor( ( diff / 1000 ) % 60 );

		return [
			`00${hours}`.slice( -2 ),
			`00${minutes}`.slice( -2 ),
			`00${seconds}`.slice( -2 ),
		];
	}
}

customElements.define( 'mr-countdown', MrCountdown );
