window.addEventListener( 'change', ( e: Event ) => {
	if ( !e.target || !( e.target instanceof HTMLSelectElement ) ) {
		return;
	}

	const select = e.target;

	if ( select.hasAttribute( 'ksl-countries' ) ) {
		handleCountryValueChange( e );

		return;
	}

	if ( select.hasAttribute( 'ksl-cities' ) ) {
		handleCityValueChange();

		return;
	}

} );

function handleCountryValueChange( e: Event ) {
	// gather html elements
	const countrySelect = document.querySelector( '[ksl-countries]' );
	const citySelectGlobal = document.querySelector( '[ksl-cities="global"]' );
	const citySelects = document.querySelectorAll( '[ksl-cities]' );

	// verify html elements exist
	if ( !countrySelect || !( countrySelect instanceof HTMLSelectElement ) ) {
		return;
	}

	if ( !citySelectGlobal || !( citySelectGlobal instanceof HTMLSelectElement ) ) {
		return;
	}

	// If this is not triggered by "cityChangedHandler" -> clear the city select value
	if ( !( e instanceof CustomEvent ) || ( !e.detail || 'manual-trigger' !== e.detail ) ) {
		citySelects.forEach( ( citySelect ) => {
			if ( !citySelect || !( citySelect instanceof HTMLSelectElement ) ) {
				return;
			}

			citySelect.value = '';
		} );
	}

	// If there is no country selected -> show the global cities list
	if ( !countrySelect.value ) {
		citySelects.forEach( ( citySelect ) => {
			if ( !citySelect || !( citySelect instanceof HTMLSelectElement ) ) {
				return;
			}

			citySelect.value = '';
			citySelect.setAttribute( 'hidden', '' );
			citySelect.setAttribute( 'disabled', '' );
		} );

		citySelectGlobal.removeAttribute( 'hidden' );
		citySelectGlobal.removeAttribute( 'disabled' );

		return;
	}

	// Show the country specific cities list
	citySelects.forEach( ( citySelect ) => {
		if ( !citySelect || !( citySelect instanceof HTMLSelectElement ) ) {
			return;
		}

		citySelect.value = '';
		citySelect.setAttribute( 'hidden', '' );
		citySelect.setAttribute( 'disabled', '' );
	} );

	const citySelectForCountry = document.querySelector( '[ksl-cities="' + countrySelect.value + '"]' );
	if ( !citySelectForCountry || !( citySelectForCountry instanceof HTMLSelectElement ) ) {
		// fallback to the global list.
		citySelectGlobal.removeAttribute( 'hidden' );
		citySelectGlobal.removeAttribute( 'disabled' );

		return;
	}

	citySelectForCountry.removeAttribute( 'hidden' );
	citySelectForCountry.removeAttribute( 'disabled' );
}

function handleCityValueChange() {
	// gather html elements
	const countrySelect = document.querySelector( '[ksl-countries]' );
	const citySelectGlobal = document.querySelector( '[ksl-cities="global"]' );

	// verify html elements exist
	if ( !countrySelect || !( countrySelect instanceof HTMLSelectElement ) ) {
		return;
	}

	if ( !citySelectGlobal || !( citySelectGlobal instanceof HTMLSelectElement ) ) {
		return;
	}

	// Country is already set, nothing more todo here
	if ( countrySelect.value ) {
		return;
	}

	// If the country is empty, set it to the country from the city value
	for ( let i = 0; i < citySelectGlobal.children.length; i++ ) {
		const c = citySelectGlobal.children[i];
		if ( !( c instanceof HTMLOptionElement ) ) {
			continue;
		}

		if ( c.value === citySelectGlobal.value ) {

			const countryForCity = c.getAttribute( 'country' );
			if ( countryForCity ) {
				countrySelect.value = countryForCity;
			}

			break;
		}
	}

	// Trigger the countryChangedHandler to update the datalist for the cities.
	const countryEvent = new CustomEvent( 'change', {
		detail: 'manual-trigger',
	} );

	countrySelect.dispatchEvent( countryEvent );
}
