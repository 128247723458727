import { throttle } from '../helpers/throttle';

class MrSpotlightHover extends HTMLElement {
	connectedCallback() {
		/* (desktop version) */
		requestAnimationFrame( () => {
			this.querySelectorAll( '.js-spotlight-hover-item' ).forEach( ( el ) => {
				el.addEventListener( 'mouseenter', ( e ) => {
					if ( !( e.target instanceof HTMLElement ) ) {
						return;
					}

					if ( 768 > window.innerWidth ) {
						return;
					}

					if ( !e.target.hasAttribute( 'idx' ) ) {
						return;
					}

					this.current = parseInt( e.target.getAttribute( 'idx' ) || '0', 10 );
				} );
			} );
		} );

		/* (mobile version) */
		const throttledToNext = throttle( () => {
			return this.goToNext();
		}, 256 );

		const throttledToPrev = throttle( () => {
			return this.goToPrev();
		}, 256 );

		this.addEventListener( 'click', ( e ) => {
			if ( !( e.target instanceof HTMLButtonElement ) ) {
				return;
			}

			if ( e.target.classList.contains( 'js-spotlight-hover__next' ) ) {
				e.preventDefault();

				throttledToNext();

				return;
			}

			if ( e.target.classList.contains( 'js-spotlight-hover__prev' ) ) {
				e.preventDefault();

				throttledToPrev();

				return;
			}
		} );
	}

	goToNext() {
		this.previous = this.current;

		// If the current is last in the list, we'd set it back to the first
		if ( ( this.current + 1 ) >= this.querySelectorAll( '.js-spotlight-hover-item' ).length ) {
			this.current = 0;

			return;
		}

		this.current = this.current + 1;
	}

	goToPrev() {
		this.previous = this.current;

		// If the current is first in the list, we'd set it back to the last
		if ( 0 > ( this.current - 1 ) ) {
			this.current = this.querySelectorAll( '.js-spotlight-hover-item' ).length - 1;

			return;
		}

		this.current = this.current - 1;
	}

	get current(): number {
		return parseInt( this.getAttribute( 'current' ) || '0', 10 );
	}

	set current( value: number ) {
		this.setAttribute( 'current', value.toString() );
	}

	get previous(): number {
		return parseInt( this.getAttribute( 'previous' ) || '0', 10 );
	}

	set previous( value: number ) {
		this.setAttribute( 'previous', value.toString() );
	}
}

customElements.define( 'mr-spotlight-hover', MrSpotlightHover );
