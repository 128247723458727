// MrFullscreen is a self contained fullscreen button for HTMLVideoElement
// supports :
// - fullscreen

import { FullscreenMediaElement, isFullscreenMediaElement } from '../type-guards/fullscreen-element';

export class MrFullscreen extends HTMLElement {
	/**
	 * When disabled clicks will not trigger fullscreen.
	 */
	get disabled(): boolean {
		return this.hasAttribute( 'disabled' );
	}

	set disabled( value: boolean ) {
		if ( value === this.disabled ) {
			return;
		}

		if ( value ) {
			this.setAttribute( 'disabled', '' );

			return;
		}

		this.removeAttribute( 'disabled' );
	}

	#mediaHasControls = false;

	#clickHandler = ( e: MouseEvent ): void => {
		// always prevent default button behavior
		e.preventDefault();

		// when disabled do nothing
		if ( this.disabled ) {
			return;
		}

		const media = this.getAttachedMedia();
		if ( !media ) {
			return;
		}

		// If we can listen for fullscreen changes we switch to native controls when entering fullscreen.
		// Otherwise do nothing.
		if ( 'onfullscreenchange' in media ) {
			this.#mediaHasControls = media.controls;
			media.controls = true;

			media.addEventListener( 'fullscreenchange', this.#fullscreenchangeHandler );
		}

		if ( media.requestFullscreen ) {
			media.requestFullscreen();

			return;
		}

		if ( media.msRequestFullscreen ) {
			media.msRequestFullscreen();

			return;
		}

		if ( media.mozRequestFullScreen ) {
			media.mozRequestFullScreen();

			return;
		}

		if ( media.webkitRequestFullscreen ) {
			media.webkitRequestFullscreen();

			return;
		}

		if ( media.webkitEnterFullscreen ) {
			media.webkitEnterFullscreen();

			return;
		}

		console.warn( 'media element doesn\'t support fullscreen' );
	};

	#fullscreenchangeHandler = (): void => {
		if ( document.fullscreenElement ) {
			// Entering fullscreen.
			// Do nothing here.
			return;
		}

		const media = this.getAttachedMedia();
		if ( !media ) {
			return;
		}

		media.controls = this.#mediaHasControls;

		media.removeEventListener( 'fullscreenchange', this.#fullscreenchangeHandler );
	};

	connectedCallback(): void {
		if ( ( 'undefined' !== typeof document.fullscreenEnabled ) && !document.fullscreenEnabled ) {
			this.disabled = true;

			return;
		}

		this.addEventListener( 'click', this.#clickHandler );
	}

	disconnectedCallback(): void {
		this.removeEventListener( 'click', this.#clickHandler );
	}

	private getAttachedMedia( withId?: string|null|undefined ): FullscreenMediaElement | null {
		const id = withId || this.getAttribute( 'data-for' );
		if ( !id ) {
			return null;
		}

		const media = document.getElementById( id );
		if ( !media || !isFullscreenMediaElement( media ) ) {
			return null;
		}

		return media;
	}
}


// TODO :
// Is there any advised ARIA attribute that should be set on fullscreen?
