export class SwapEvent extends CustomEvent<undefined> {}

// WARNING: sub optimal element
class MrVideoSwap extends HTMLElement {
	#clickHandler = ( e: MouseEvent ): void => {
		if ( !( e.currentTarget instanceof HTMLAnchorElement ) ) {
			return;
		}

		e.preventDefault();

		const videoLabel = this.querySelector( '.js-video-label' );
		if ( videoLabel ) {
			videoLabel.classList.add( 'is-loading' );
		}

		this.showFullVideo();
	};

	#labelTransitionEndHandler = (): void => {
		const videoLabel = this.querySelector( '.js-video-label' );
		if ( !videoLabel ) {
			return;
		}

		videoLabel.classList.remove( 'is-loaded' );
		videoLabel.classList.add( 'is-hidden' );

		videoLabel.removeEventListener( 'transitionend', this.#labelTransitionEndHandler );
	};

	connectedCallback(): void {
		requestAnimationFrame( () => {
			const videoLabel = this.querySelector( '.js-video-label' );
			if ( videoLabel && ( videoLabel instanceof HTMLElement ) ) {
				videoLabel.addEventListener( 'click', this.#clickHandler );
			}

		} );
	}

	disconnectedCallback(): void {
		const videoLabel = this.querySelector( '.js-video-label' );
		if ( videoLabel && ( videoLabel instanceof HTMLElement ) ) {
			videoLabel.removeEventListener( 'click', this.#clickHandler );
			videoLabel.removeEventListener( 'transitionend', this.#labelTransitionEndHandler );
		}

	}

	showFullVideo() {
		if ( this.hasAttribute( 'swapped' ) ) {
			return;
		}

		const video = this.getAttachedVideo();
		if ( !video ) {
			return;
		}

		let videoSource: string | null;
		if ( 768 < window.innerWidth ) {
			videoSource = this.getAttribute( 'vimeo' );
		} else {
			videoSource = this.getAttribute( 'mobile-vimeo' ) || this.getAttribute( 'vimeo' );
		}

		if ( !videoSource ) {
			return;
		}

		video.src = videoSource;
		this.setAttribute( 'swapped', '' );

		const videoLabel = this.querySelector( '.js-video-label' );
		if ( videoLabel ) {
			videoLabel.classList.add( 'is-loaded' );
			videoLabel.addEventListener( 'transitionend', this.#labelTransitionEndHandler );
		}

		video.dispatchEvent( new SwapEvent( 'swap', {
			bubbles: true,
			cancelable: true,
		} ) );
	}

	getAttachedVideo(): HTMLVideoElement| null {
		const videoId = this.getAttribute( 'for' );
		if ( !videoId ) {
			return null;
		}

		const video = document.getElementById( videoId );
		if ( !video || !( video instanceof HTMLVideoElement ) ) {
			return null;
		}

		return video;
	}
}

customElements.define( 'mr-video-swap', MrVideoSwap );
