// https://github.com/shahzam/DialogTriggerJS/blob/master/src/dialog_trigger.js
type DialogTriggerOptions = {
	trigger: 'exitIntent' | 'scrollDown' | 'scrollUp',
	percentDown: number, // Used for 'percent' to define a down scroll threshold of significance (based on page height)
	percentUp: number, // Used for 'percent' to define a threshold of upward scroll after down threshold is reached
}

export class DialogTrigger {
	callback: () => void;

	complete: boolean;

	options: DialogTriggerOptions;


	constructor( callback: () => void, options: DialogTriggerOptions ) {
		// Becomes this.options
		const defaults = {
			trigger: 'exitIntent',
			percentDown: 0.5,
			percentUp: 0.1,
		};

		this.callback = callback;
		this.complete = false;

		this.options = Object.assign( {}, defaults, options );
	}

	#mouseLeaveHandler = ( event: MouseEvent ) => {
		if ( !this.complete && event.clientY < 0 ) {
			this.callback();
			this.complete = true;

			document.removeEventListener( 'mouseleave', this.#mouseLeaveHandler );
		}
	};

	#scrollHandler = () => {
		const pageHeight = document.body.scrollHeight;
		if ( pageHeight === 0 ) {
			return;
		}

		const scrollPercent = window.scrollY / ( document.body.scrollHeight - window.innerHeight );
		if (
			( this.options.trigger === 'scrollDown' && scrollPercent > this.options.percentDown ) ||
			( this.options.trigger === 'scrollUp' && scrollPercent < this.options.percentUp )
		) {
			window.removeEventListener( 'scroll', this.#scrollHandler );

			if ( !this.complete ) {
				if ( 'onscrollend' in window ) {
					window.addEventListener( 'scrollend', () => {
						this.callback();
						this.complete = true;
					}, {
						once: true,
					} );
				} else {
					this.callback();
					this.complete = true;
				}
			}
		}
	};

	listen() {
		if ( this.options.trigger === 'exitIntent' ) {
			document.addEventListener( 'mouseleave', this.#mouseLeaveHandler );
		} else if (
			this.options.trigger === 'scrollDown' ||
			this.options.trigger === 'scrollUp'
		) {
			window.addEventListener( 'scroll', this.#scrollHandler, {
				passive: true,
			} );
		}
	}

	cancel() {
		this.complete = true;

		document.removeEventListener( 'mouseleave', this.#mouseLeaveHandler );
		window.removeEventListener( 'scroll', this.#scrollHandler );
	}
}
