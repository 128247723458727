import { MrModalDialog } from '@mrhenry/wp--mr-interactive';

class MrNavigationOverlay extends MrModalDialog {

	mediaQuery = window.matchMedia( '(min-width: 1024px)' );

	resizeHandler = () => {
		this.updateState( 'close' );

		this.mediaQuery.removeListener( this.resizeHandler );
	};

	override async willOpen() {
		await super.willOpen();

		document.body.style.overflow = 'hidden';

		const noticeBar = document.getElementById( 'notice-bar' );
		if ( noticeBar ) {
			noticeBar.style.position = 'fixed';
		}

		const menu = document.querySelector( '.site-header__main' ) as HTMLElement | null;
		if ( menu ) {
			menu.style.display = 'none';
		}

		const innerMenu = document.querySelector( '.site-header__mobile-navigation' ) as HTMLElement | null;
		if ( innerMenu ) {
			innerMenu.style.display = 'none';
		}

		this.mediaQuery.removeListener( this.resizeHandler );
		this.mediaQuery.addListener( this.resizeHandler );
	}

	override async willClose() {
		await super.willClose();

		this.mediaQuery.removeListener( this.resizeHandler );

		document.body.style.overflow = 'visible';

		const noticeBar = document.getElementById( 'notice-bar' );
		if ( noticeBar ) {
			noticeBar.style.position = '';
		}

		const menu = document.querySelector( '.site-header__main' ) as HTMLElement | null;
		if ( menu ) {
			menu.style.display = 'block';
		}

		const innerMenu = document.querySelector( '.site-header__mobile-navigation' ) as HTMLElement | null;
		if ( innerMenu ) {
			innerMenu.style.display = '';
		}
	}
}

customElements.define( 'mr-navigation-overlay', MrNavigationOverlay );
