import { SwapEvent } from './video-swap';

// WARNING: sub optimal element
class MrVideo extends HTMLElement {
	#video: HTMLVideoElement | null = null;

	#poster: HTMLElement | null = null;

	#initial = true;

	#videoAttributeObserver: MutationObserver|null = null;

	#swapHandler = ( e: Event ): void => {
		if ( !( e instanceof SwapEvent ) ) {
			return;
		}

		this.hidePoster();
	};

	#canplaythroughHandler = (): void => {
		this.hidePoster();
	};

	connectedCallback() {
		requestAnimationFrame( () => {
			const poster = this.querySelector( '.js-video-poster' );
			if ( poster instanceof HTMLElement ) {
				this.#poster = poster;
			}

			const video = this.getAttachedVideo();
			if ( video && video instanceof HTMLVideoElement ) {
				this.#video = video;

				// Is also done through inline js, but do it again just to be sure.
				video.controls = false;

				this.#video.addEventListener( 'swap', this.#swapHandler );

				this.#video.addEventListener( 'canplaythrough', this.#canplaythroughHandler );

				this.startObservingVideoAttributes( video );
			}

			this.#initial = true;
		} );

		this.setupVideoPlayback();
	}

	disconnectedCallback() {
		const video = this.getAttachedVideo();
		if ( video && video instanceof HTMLVideoElement ) {
			video.removeEventListener( 'swap', this.#swapHandler );

			video.removeEventListener( 'canplaythrough', this.#canplaythroughHandler );
		}

		this.#video = null;
		this.#poster = null;
		this.#initial = true;

		this.stopObservingVideoAttributes();
	}

	// user events

	hidePoster() {
		if ( this.#initial && this.#poster ) {
			this.#poster.classList.add( 'is-hidden' );

			this.#poster.addEventListener( 'transitionend', () => {
				if ( this.#poster && this.#poster.parentNode ) {
					this.#poster.parentNode.removeChild( this.#poster );
				}
			} );
		}

		this.#initial = false;
	}

	setupVideoPlayback() {
		requestAnimationFrame( () => {
			Object.assign( this.style, {
				display: 'block',
				position: 'relative',
			} );

			const video = this.getAttachedVideo();
			if ( !video ) {
				return;
			}

			const mobileVideoSource = this.getAttribute( 'mobile-video-loop' )?.trim();
			const desktopVideoSource = this.getAttribute( 'video-loop' )?.trim();


			if ( mobileVideoSource && '' !== mobileVideoSource ) {
				if ( 768 > window.innerWidth ) {
					// Add the video src of mobile
					video.src = mobileVideoSource;

					return;
				}
			}

			// Add the video src of desktop
			if ( desktopVideoSource && '' !== desktopVideoSource ) {
				video.src = desktopVideoSource;

				return;
			}
		} );
	}

	// Browsers allow users to manually enable native controls
	// Observer this behaviour and set an attribute with which we can hide custom controls.
	startObservingVideoAttributes( video : HTMLVideoElement ) {
		// Create an observer instance linked to the callback function
		this.#videoAttributeObserver = new MutationObserver( ( ( mutationsList ) => {
			// Use traditional 'for loops' for IE 11
			for ( const mutation of mutationsList ) {
				if ( 'attributes' === mutation.type && 'controls' === mutation.attributeName ) {
					const currentVideo = this.getAttachedVideo();
					if ( !currentVideo ) {
						return;
					}

					if ( currentVideo !== video ) {
						return; // shouldn't happen, but ....
					}

					if ( currentVideo.controls ) {
						this.setAttribute( 'native-controls', '' );
					} else {
						this.removeAttribute( 'native-controls' );
					}
				}
			}
		} ) );

		// Start observing the target node for configured mutations
		this.#videoAttributeObserver.observe( video, {
			attributes: true,
			childList: false,
			subtree: false,
		} );
	}

	stopObservingVideoAttributes(): void {
		if ( !this.#videoAttributeObserver ) {
			return;
		}

		this.#videoAttributeObserver.disconnect();
		this.#videoAttributeObserver = null;
	}

	getAttachedVideo(): HTMLVideoElement| null {
		const videoId = this.getAttribute( 'for' );
		if ( !videoId ) {
			return null;
		}

		const video = document.getElementById( videoId );
		if ( !video || !( video instanceof HTMLVideoElement ) ) {
			return null;
		}

		return video;
	}
}

customElements.define( 'mr-video', MrVideo );
