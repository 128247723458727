const isHoverDevice = window.matchMedia( '(hover: hover)' );

class MrHotspot extends HTMLElement {
	#toggleHandler = ( event: Event ) => {
		if ( event.target !== this ) {
			return;
		}

		if ( !this.isOpen() ) {
			event.preventDefault();
			event.stopPropagation();

			this.open();
		}
	};

	#openHandler = () => {
		if ( !this.isOpen() ) {
			this.open();
		}
	};

	#closeHandler = () => {
		if ( this.isOpen() ) {
			this.close();
		}
	};

	connectedCallback() {
		this.parentElement?.addEventListener( 'click', this.#toggleHandler );

		this.parentElement?.addEventListener( 'focus', () => {
			this.ensureDetailsFitsWithinBounds();
			this.closeAllOtherHotspots();
		} );

		if ( isHoverDevice.matches ) {
			this.parentElement?.addEventListener( 'mouseenter', this.#openHandler );
			this.parentElement?.addEventListener( 'mouseleave', this.#closeHandler );
		}
	}

	isOpen() {
		return this.getAttribute( 'data-state' ) === 'open';
	}

	open() {
		this.closeAllOtherHotspots();
		this.ensureDetailsFitsWithinBounds();
		this.setAttribute( 'data-state', 'open' );
	}

	close() {
		this.setAttribute( 'data-state', 'closed' );
	}

	ensureDetailsFitsWithinBounds() {
		if ( this.hasAttribute( 'anchor' ) ) {
			return;
		}

		const mainBoundaries = this.closest( '.l-showcase--hotspots' )?.getBoundingClientRect();
		const detailBoundaries = this.querySelector( '[hotspot-details]' )?.getBoundingClientRect();
		if ( !mainBoundaries || !detailBoundaries ) {
			return;
		}

		const mainBoundariesWidth = mainBoundaries.width;
		const hotspotIsOnTheRight = mainBoundariesWidth / 2 < detailBoundaries.left;

		const goesOverRightEdge = ( detailBoundaries.right + 10 ) > mainBoundaries.right;
		const goesOverBottomEdge = ( detailBoundaries.bottom + 10 ) > mainBoundaries.bottom;

		if ( goesOverRightEdge && hotspotIsOnTheRight && goesOverBottomEdge ) {
			this.setAttribute( 'anchor', 'right bottom' );
		} else if ( goesOverRightEdge && hotspotIsOnTheRight ) {
			this.setAttribute( 'anchor', 'right top' );
		} else if ( goesOverBottomEdge ) {
			this.setAttribute( 'anchor', 'left bottom' );
		} else {
			this.setAttribute( 'anchor', 'left top' );
		}
	}

	closeAllOtherHotspots() {
		document.querySelectorAll<MrHotspot>( 'mr-hotspot' ).forEach( ( otherHotspot ) => {
			if ( this === otherHotspot ) {
				return;
			}

			if ( typeof otherHotspot.close === 'function' ) {
				otherHotspot.close();
			}
		} );
	}
}

customElements.define( 'mr-hotspot', MrHotspot );
