import '@mrhenry/wp--bugsnag-config';
import './shared/shared';

import './modules/countdown';
import './modules/country-city-select';
import './modules/distance-from-me';
import './modules/klaviyo-subscribe';
import './modules/navigation-bar-info';
import './modules/spotlight-hover';
import './modules/store-locator-preview-list';
import './modules/mr-hotspot';

/* Video */
import './modules/video/video-swap';
import './modules/video/video';
import './modules/video/progress';
import './modules/video/scrub';
import './modules/video/user-interacting';

/* Mr Interactive */
import { MrMuteToggle, MrPlayPauseToggle, MrFullscreen } from '@mrhenry/wp--mr-interactive';

customElements.define( 'mr-mute-toggle', MrMuteToggle );
customElements.define( 'mr-play-pause-toggle', MrPlayPauseToggle );
customElements.define( 'mr-fullscreen', MrFullscreen );
