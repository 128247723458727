// Based on https://www.w3.org/WAI/tutorials/menus/flyout/

class MrFlyOutMenuTrigger extends HTMLElement {
	/* Used to store the timeout ID */
	#mouseTimer = 0;

	/** Click-events on the fly-out trigger should toggle the menu.
	 * This will also work for enter and space keypresses.
	 */
	#clickHandler = ( e: MouseEvent ): void => {
		if ( !e ) {
			return;
		}

		const flyOutTarget = this.getFlyOutMenuFromEvent( e );
		if ( !flyOutTarget ) {
			return;
		}

		this.toggleFlyOutMenu( flyOutTarget );
	};

	/** Custom hover functionality for extra A11Y:
	 * show menu on mouseover, and clear the mouseTimer.
	 * (also see mouseoutHandler)
	 */
	#mouseoverHandler = ( e: MouseEvent ): void => {
		if ( !e ) {
			return;
		}

		const flyOutTarget = this.getFlyOutMenuFromEvent( e );
		if ( !flyOutTarget ) {
			return;
		}

		window.clearTimeout( this.#mouseTimer );

		this.showFlyOutMenu( flyOutTarget );
	};

	/** Custom hover functionality for extra A11Y:
	 * show menu on mouseout, and set the mouseTimer.
	 * This gives users with less-precise mousehandling more time to reach
	 * the fly-out menu, and allows for "errors" (moving mouse outside element)
	 * (also see mouseoverHandler)
	 */
	#mouseoutHandler = ( e: MouseEvent ): void => {
		if ( !e ) {
			return;
		}

		const flyOutTarget = this.getFlyOutMenuFromEvent( e );
		if ( !flyOutTarget ) {
			return;
		}

		this.#mouseTimer = window.setTimeout( () => {
			this.hideFlyOutMenu( flyOutTarget );
		}, 1000 );
	};

	/* Add event listener */
	connectedCallback(): void {
		this.addEventListener( 'click', this.#clickHandler );
		this.addEventListener( 'mouseover', this.#mouseoverHandler );
		this.addEventListener( 'mouseout', this.#mouseoutHandler );
	}

	/* Remove event listener */
	disconnectedCallback(): void {
		this.removeEventListener( 'click', this.#clickHandler );
		this.removeEventListener( 'mouseover', this.#mouseoverHandler );
		this.removeEventListener( 'mouseout', this.#mouseoutHandler );
	}

	/* Helper function to select fly-out target from events added above */
	getFlyOutMenuFromEvent( e: MouseEvent ): HTMLElement|null {
		if ( !e || !e.currentTarget ) {
			return null;
		}

		let trigger: HTMLElement | null = null;
		if ( e.currentTarget instanceof HTMLElement ) {
			trigger = e.currentTarget;
		}

		if ( !trigger || !trigger.getAttribute( 'for' ) ) {
			return null;
		}

		const flyOutTargetId = trigger.getAttribute( 'for' );
		const flyOutTarget = document.querySelector( `#${flyOutTargetId}` );

		if ( flyOutTarget && ( flyOutTarget instanceof HTMLElement ) ) {
			return flyOutTarget;
		}

		return null;
	}

	toggleFlyOutMenu( target: HTMLElement ): void {
		if ( !target ) {
			return;
		}

		if (
			!target.getAttribute( 'aria-expanded' ) ||
			(
				target.getAttribute( 'aria-expanded' ) &&
				'false' === target.getAttribute( 'aria-expanded' )
			)
		) {
			if ( target.parentElement ) {
				target.parentElement.setAttribute( 'flyout-active', '' );
			}

			target.setAttribute( 'aria-expanded', 'true' );
			this.dispatchOpenEvent();
		} else {
			if ( target.parentElement ) {
				target.parentElement.removeAttribute( 'flyout-active' );
			}

			target.setAttribute( 'aria-expanded', 'false' );
		}
	}

	hideFlyOutMenu( target: HTMLElement ): void {
		if ( !target ) {
			return;
		}

		if ( target.parentElement ) {
			target.parentElement.removeAttribute( 'flyout-active' );
		}

		target.setAttribute( 'aria-expanded', 'false' );
	}

	showFlyOutMenu( target: HTMLElement ): void {
		if ( !target ) {
			return;
		}

		if ( target.parentElement ) {
			target.parentElement.setAttribute( 'flyout-active', '' );
		}

		target.setAttribute( 'aria-expanded', 'true' );
		this.dispatchOpenEvent();
	}

	/* Other fly-out menus should close when opening a new one */
	dispatchOpenEvent() {
		window.dispatchEvent(
			new CustomEvent(
				'mr-fly-out-menu-trigger:openedMenu',
				{
					bubbles: true,
					cancelable: true,
					detail: {
						id: this.getAttribute( 'for' ) ?? null,
					},
				}
			)
		);
	}
}

customElements.define( 'mr-fly-out-menu-trigger', MrFlyOutMenuTrigger );
