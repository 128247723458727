import { setText } from '../helpers/set-text';
import { requestCurrentPosition, calcCrowKm } from '../helpers/position';

class MrDistanceFromMe extends HTMLElement {
	#observer: IntersectionObserver | null = null;

	connectedCallback() {
		this.requestLocation();
	}

	disconnectedCallback() {
		if ( this.#observer ) {
			this.#observer.unobserve( this );
			this.#observer.disconnect();
			this.#observer = null;
		}
	}

	async requestLocation() {
		if ( ( 'IntersectionObserver' in window ) ) {
			this.#observer = new IntersectionObserver(
				async( entries ) => {
					if ( 0 === entries.length ) {
						return;
					}

					entries.forEach( async( entry ) => {
						if ( entry.target === this && entry.isIntersecting ) {
							const position = await requestCurrentPosition();
							if ( position ) {
								this.updateInnerHTML( position );

								if ( this.#observer ) {
									this.#observer.unobserve( this );
									this.#observer.disconnect();
									this.#observer = null;
								}
							}
						}
					} );
				}
			);

			this.#observer.observe( this );

			return;
		}

		const position = await requestCurrentPosition();
		if ( position ) {
			this.updateInnerHTML( position );
		}

		return;
	}

	updateInnerHTML( position: GeolocationPosition ) {
		const coordinates = this.getAttribute( 'coordinates' );
		if ( !coordinates ) {
			return;
		}

		const format = this.getAttribute( 'format' );
		if ( !format ) {
			return;
		}

		// google maps url query format
		const splitCoordinates = coordinates.split( ',' );
		if ( 2 !== splitCoordinates.length ) {
			return;
		}

		const latitude = parseFloat( splitCoordinates[0] );
		const longitude = parseFloat( splitCoordinates[1] );

		if ( !longitude ) {
			return;
		}

		if ( !latitude ) {
			return;
		}

		const distanceKm = calcCrowKm( latitude, longitude, position.coords.latitude, position.coords.longitude );
		setText( this, format.replace( new RegExp( '{distance}', 'g' ), distanceKm.toString() ) );
	}
}

customElements.define( 'mr-distance-from-me', MrDistanceFromMe );
